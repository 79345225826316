import React from "react";
import "./styles.scss";
import { FormattedMessage } from "react-intl";
import WhitePillButton from "../../components/WhitePillButton/WhitePillButton.jsx";

const EndPage = () => {
  const liptonURL = () => {
    window.location.href = "https://www.liptonicetea.nl/home.html";
  };
  const liptonClubURL = () => {
    window.location.href =
      "https://www.liptonicetea.nl/acties/stemopjefavoriet.html";
  };

  return (
    <>
      <div
        className="end-page-container"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <div className="dialog-container">
          <h2>
            <FormattedMessage id="end_page_top_message" />
          </h2>
          <h1>
            <FormattedMessage id="end_page_middle_message" />
          </h1>
          <p>
            <FormattedMessage id="end_page_bottom_message" />
          </p>
          <div className="button-container">
            <WhitePillButton
              buttonText={<FormattedMessage id="lipton_campaign" />}
              onClick={liptonClubURL}
              style={{
                width: "auto",
                padding: "10px 30px",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EndPage;
