import React from "react";
import "./styles.scss";
import { FormattedMessage } from "react-intl";

//Asset imports
import first from "../../../assets/img/winner_icons/Winner_icon_first.png";
import second from "../../../assets/img/winner_icons/Winner_icon_second.png";
import third from "../../../assets/img/winner_icons/Winner_icon_third.png";

const ScoreboardResults = () => {
  return (
    <>
      <div className="scoreboard-content-container">
        <div className="scoreboard-text">
          {/* <p>
            <FormattedMessage id="scoreboard_results_info" />
          </p> */}

          {/* --- Uncomment to display 'we are counting the votes' message' (for 29th August) --- */}
          <p className="announcement">
            <FormattedMessage id="scoreboard_results_coming_soon_2"  values={{ link: <a href="https://www.liptonicetea.nl/acties/stemopjefavoriet.html">www.liptonicetea.nl</a> }} />
          </p>
        </div>

        {/* --- comment out below to hide current scoreboard when counting votes (for 29th August)--- */}
        {/* <div className="scoreboard-title">
          <h2>
            <FormattedMessage id="scoreboard_title" />
          </h2>
        </div> */}

        {/* ------------- UPDATE WEEKLY ------------- */}
        {/* <div className="scoreboard-results">
          <div className="scoreboard-list">
            <div className="top3">
              <span>
                <img alt="" src={first} />
              </span>
              Hotel Café Restaurant de Bourgondier
            </div>
            <div className="top3">
              <span>
                <img alt="" src={second} />
              </span>
              Grand-Café De Brass
            </div>
            <div className="top3">
              <span>
                <img alt="" src={third} />
              </span>
              Tearoom 't Pastorieke
            </div>
            <div>
              <span className="remaining-positions">4.</span>Lunchroom Stadswaag
            </div>
            <div>
              <span className="remaining-positions">5.</span>Lunchroom Croissanterie Croissy
            </div>
            <div>
              <span className="remaining-positions">6.</span>Lunchroom Broodje Aap
            </div>
            <div>
              <span className="remaining-positions">7.</span>Café Xero
            </div>
            <div>
              <span className="remaining-positions">8.</span>t Pannehuske
            </div>
            <div>
              <span className="remaining-positions">9.</span>Café Banka
            </div>
            <div>
              <span className="remaining-positions">10.</span>De Pyramide van Austerlitz
            </div>
            <div>
              <span className="remaining-positions">11.</span>Cafe-Maasterras 't Veerhuis
            </div>
            <div>
              <span className="remaining-positions">12.</span>Café Camelot
            </div>
            <div>
              <span className="remaining-positions">13.</span>Stadscafé Jan
            </div>
            <div>
              <span className="remaining-positions">14.</span>Hotel et le Café de Paris
            </div>
            <div>
              <span className="remaining-positions">15.</span>Restaurant & PartyService De Lelie
            </div>
            <div>
              <span className="remaining-positions">16.</span>Hotel & Wereldeethuis Bazar
            </div>
            <div>
              <span className="remaining-positions">17.</span>Restaurant Café 't Jagersnest
            </div>
            <div>
              <span className="remaining-positions">18.</span>Eetcafé De Appel
            </div>
            <div>
              <span className="remaining-positions">19.</span>Strandhuys De Enk
            </div>
            <div>
              <span className="remaining-positions">20.</span>Café d'Oranjeboom
            </div>
            <div>
              <span className="remaining-positions">21.</span>Bar Restaurant 't Zeeltje
            </div>
            <div>
              <span className="remaining-positions">22.</span>Koffiebar Ut Bakkes Maasbendj
            </div>
            <div>
              <span className="remaining-positions">23.</span>Brasserie De Bourgondier 
            </div>
            <div>
              <span className="remaining-positions">24.</span>Het Postkantoor
            </div>
            <div>
              <span className="remaining-positions">25.</span>Brasserie De 2 Bourgondiërs
            </div>
          </div>
          <div className="scoreboard-footer">
            <p>
              <FormattedMessage id="scoreboard_results_footer" />
            </p>
          </div>
        </div>*/}
      </div> 
    </>
  );
};

export default ScoreboardResults;
