import { LOCALES } from "./locales.js";

export const messages = {
  [LOCALES.DUTCH]: {
    //HOLDING PAGE
    coming_soon: "BINNENKORT",

    // DESKTOP ------------------------------------------------------------------------------------------------
    desktop_redirect_message:
      "DEZE ACTIE IS ALLEEN BESCHIKBAAR VOOR DE MOBIELE TELEFOON",
    desktop_redirect_scan: "SCAN DE QR CODE MET JE TELEFOON & STEM!",
    //footer
    desktop_lipton: "LIPTON",
    desktop_rules: "REGLEMENT",
    desktop_terms_conditions: "ACTIEVOORWAARDEN :",
    desktop_consumer: "VOOR CONSUMENTEN",
    desktop_terraces: "VOOR TERRASSEN",

    // LANDING PAGE ----------------------------------------------------------------------------------------------
    landing_page_heading: "WELKOM BIJ LIPTON ICE TEA DOE MEE AAN DE ACTIE",
    landing_terrace: "STEM OP JE FAVORIETE LIPTON TERRAS",
    landing_club: "ZIN IN EEN FEESTJE",

    // START PAGE ------------------------------------------------------------------------------------------------
    // intro
    start_page_welcome: "WELKOM",
    start_page_lipton: "LIPTON TERRAS",
    start_page_edition: "2024 EDITIE",
    start_button: "START!",
    campaign_text: "sdfsds ",
    campaign_link: "De Lipton Club",
    // gif
    enter_button: "DOORGAAN",
    // legal lines
    legal_line: "Op deze Actie zijn het ",
    legal_line2: " van toepassing.",
    legal_line3: " en de ",
    legal_href2: "actievoorwaarden",
    legal_href: "'Reglement van Hét Lipton Terras van 2024' ",

    // VOTING PAGE ------------------------------------------------------------------------------------------------
    // initial Search Menu
    please_use_searchbar_no_venue_1: "ZOEK JE FAVORIETE",
    please_use_searchbar_no_venue_2: "LIPTON TERRAS",
    search_bar_placeholder: "Terras zoeken...",
    go_button: "ZOEKEN",
    find_it_on_the_map: "VERKEN DE LIPTON\n TERRASSENKAART",
    // input validation
    text_input_invalid: "Vul dit veld in.",
    email_input_invalid: "Voer een geldig e-mailadres in.",
    short_input: "Tip: Probeer een zoekopdracht met minimaal 3 karakters!",
    wrong_input: "Tip: Vul je zoekopdracht aan met de naam van het terras!",
    venue_not_found: "Jouw favoriete Lipton terras is niet gevonden 🧐",
    not_the_one: "Niet degene waar je naar op zoek bent?",
    please_use_searchbar:
      "Gebruik de zoekbalk om jouw favoriete terras te vinden.",

    permission_denied:
      "Het lijkt erop dat je ons geen toegang hebt gegeven tot je locatie. Geen probleem!  Je kan ook jouw favoriete Lipton Terras vinden m.b.v. de lijst optie.\n \n Liever de kaart gebruiken?\n Sta locatievoorzieningen toe in je telefooninstellingen en laad de pagina opnieuw.", // Check with team
    permission_denied_2:
      "Het lijkt er op dat je ons geen toegang tot je locatie hebt gegeven, geen probleem! \n \n Wissel met de toggle snel naar de lijst om jouw Favoriete Lipton Terras te vinden! Toch de kaart gebruiken? Sta locatievoorzieningen toe in je telefoon en refresh de pagina.", // Check with team
    has_voted: "Je hebt al gestemd vandaag! Kom morgen terug.",
    // map view
    your_favorite_terrace: "JE FAVORIETE LIPTON TERRAS IS...",
    vote_button: "STEM",
    // list View
    pick_me: "KIES",

    // THANKS FOR VOTING PAGE ------------------------------------------------------------------------------------------------
    // contest message popup
    contest_message_heading: "SCHRIJF JE IN EN MAAK KANS!",
    contest_message_text:
      "Laat je e-mailadres achter en maak kans om 1 van de 50 horecavouchers te winnen t.w.v €100,-",
    accept_contest_button: "JA, GRAAG!",
    redirect_lipton_club: "LIPTON CLUB",
    view_terms_and_conditions: "Om de Algemene Voorwaarden te bekijken, ",
    view_terms_link: "klik hier.",
    // competition popup
    sign_up_to_win_enter: "SCHRIJF JE IN EN MAAK KANS!",
    competition_sub_text:
      "Vul het formulier hieronder in en maak kans om één van de 50 horecavouchers t.w.v. 100 euro te winnen.",
    raffle_input_placeholder: "Ik heb op dit terras gestemd, omdat...*",
    enter_email_input_placeholder: "Mijn e-mail is...*",
    name_input_placeholder: "Mijn voornaam is...*",
    //age checkbox
    age_checkbox_1: "Ik ben ouder dan 18 jaar en ga akkoord met het ",
    age_checkbox_tc_href_terms: "actievoorwaarden",
    age_checkbox_2: " en de ",
    age_checkbox_tc_href_rules: "wedstrijdreglement",
    confirm_age_alert:
      "Je dient je leeftijd te bevestigen door het hokje aan te vinken.",
    //email checkbox
    unilever_marketing_opt_in_text_1: `Ja, ik word graag op de hoogte gehouden van nieuws over deze actie en aanbiedingen van Lipton Ice Tea en andere `,
    unilever_marketing_opt_in_text_anchor: `Unilever`,
    unilever_marketing_opt_in_text_2: ` merken, via e-mail en advertenties, afgestemd op mijn persoonlijke voorkeuren en interesses.`,
    already_joined_email: "Dit email adres doet al mee!",
    submit_button: "VERSTUUR",
    // come back tomorrow
    come_back_tomorrow_1: "KOM MORGEN \n TERUG EN",
    come_back_tomorrow_2: "STEM",
    come_back_tomorrow_3: "NOG EEN KEER!",
    see_campaign: "ZIE ANDERE",
    see_lipton_campaign: "LIPTON CAMPAGNES",

    // MENU ------------------------------------------------------------------------------------------------
    menu_heading: "ALLES OVER DEZE ACTIE",
    menu_scoreboard: "SCOREBOARD",
    menu_competition: "DE WEDSTRIJD",
    menu_terms_conditions: "ACTIEVOORWAARDEN",
    menu_faq: "VEELGESTELDE VRAGEN / FAQ",
    menu_reglement_lipton: "REGLEMENT HET LIPTON TERRAS 2024",
    menu_cookies: "COOKIES & INSTELLINGEN",
    // DE WEDSTRIJD
    menu_prizes: "DE PRIJZEN!",
    menu_winners: "DE WINNAARS",
    menu_rules: "DE SPELREGELS",
    // ACTIEVOORWAARDEN
    menu_terms_conditions_consumers: "VOOR CONSUMENTEN",
    menu_terms_conditions_business: "VOOR TERRASSEN",

    select_language: "SELECTEER JE TAAL",

    // MENU CONTEN: PRIZES
    participants_vote_heading: "CONSUMENTENPRIJZEN",
    participants_vote_content_1: `Jij kan meedoen aan de activatie van ‘Hét Lipton Terras van 2024’ door na het stemmen het formulier in te vullen. Ga naar `,
    participants_vote_content_link: `www.hetliptonterras.nl `,
    participants_vote_content_2: `en stem op je favoriete terras! Vul daarna het formulier in en maak kans op één van de 50 horecavouchers t.w.v. €100,-. Pssst… wist je trouwens dat je iedere dag opnieuw kan stemmen op je favoriete Lipton-terras? `,
    participants_business_heading: "LIPTON TERRAS PRIJZEN",
    participants_business_content: `De horeca maakt in totaal kans op bedrag tot wel €150.000. Vet he! Met dit investeringsbudget kunnen de winnaars de terrassen nóg mooier maken. Maar hoe werkt het nu precies? Nou, als Lipton Terras moet je zoveel mogelijk stemmen krijgen van je gasten! Zodra jij de meeste stemmen hebt gekregen, in de maand of in Nederland, win je de volgende prijzen:`,
    participants_business_prizes_1: `• De meeste unieke stemmen van Nederland? Het Lipton terras op de eerste plek krijgt: €50.000 excl. BTW`,
    participants_business_prizes_2: `• Op 1 na de meeste unieke stemmen van Nederland? Het Lipton terras op de tweede plek krijgt: €30.000 euros excl. BTW`,
    participants_business_prizes_3: `• Op 2 na de meeste unieke stemmen van Nederland? Het Lipton terras op de derde plek krijgt: €15.000 euros excl. BTW`,
    participants_business_prizes_4: `• De meeste stemmen van de maand? Het Lipton terras dat de meeste unieke stemmen van de maand heeft gekregen krijgt €10.000 euros excl. BTW. Dat betekent dus dat in iedere maand (x4) een winnaar van het Lipton Terras van 2024 word uitgeroepen! Zo maken de horeca ondernemers nog eens extra kans om te winnen!`,
    // MENU CONTEN: WINNERS
    winners_consumer_heading: "DE CONSUMENTEN WINNAARS",
    winners_consumer_content:
      "Zodra jij het formulier voor de winactie invult na het stemmen op je favoriete Lipton terras, maak jij kans om één van de 50 horecavouchers t.w.v. 100 euro te winnen. De uitslag wordt uiterlijk gemaakt 22 September 2024 bekend gemaakt. Dus.. zet die datum maar vast in je agenda!",
    winners_terrace_heading: "DE TERRASSEN ",
    winners_terrace_content_1:
      "Wie krijgt de meeste unieke stemmen? The battle is on! Er zijn twee momenten waar prijzen gewonnen kunnen worden, zet deze data vast in je agenda. Consumenten en je gasten kunnen stellen t/m 31 augustus 2024. Het eerste moment vindt plaats wanneer wij de maandelijkse winnaars bekend maken. Het Lipton Terras met de meeste unieke stemmen van de maand wint (zie ‘het scoreboard’ en ‘de prijzen’ in het menu scherm. Wij nemen na die maand contact op met de winnaar. ",
    winners_terrace_content_2:
      "Na de maandelijkse momenten, kunnen consumenten en gasten doorgaan met stemmen t/m 31 augustus 2024. Het Lipton Terras met de meeste stemmen op dat moment wint. (zie ‘de prijzen’ in het menu scherm. Wij nemen in die maand contact op met de winnaars.",
    // MENU CONTEN: RULES
    rules_vote_heading: "STEMMEN EN WINACTIE",
    rules_content_1:
      "• Als consument kan je maximaal één stem per dag uitbrengen.",
    rules_content_2: `• Het uitbrengen van een stem is voorbehouden aan consumenten (natuurlijke personen). Lipton klanten mogen niet stemmen.  `,
    rules_content_3: `• Om competitievervalsing en fraude te voorkomen en bestrijden, zal Unilever de uitgebrachte stemmen monitoren. Wanneer Unilever het vermoeden heeft dat een Lipton klant fraudeert of de competitie op enige andere wijze probeert te vervalsen, kan Unilever de Lipton klant geheel van deelname uitsluiten, en/of uitgebrachte stemmen ongeldig verklaren.`,
    rules_win_heading: "WINACTIE",
    rules_win_content: `• Als consument kan je maximaal één keer meedoen (met je emailadres) met de horecavoucher winactie. Voor meer details raadpleeg de `,
    rules_win_content_2: ` van ‘Het Lipton Terras van 2024’ via het menu.`,
    rules_win_content_href: `actievoorwaarden`,
    // MENU CONTENT: FAQ
    faq_question_1: `HOE VAAK KAN IK STEMMEN?`,
    faq_answer_1: `Jij kan als consument één keer per dag stemmen. Dus kom elke dag even terug om je favoriete terras te steunen!`,
    faq_question_2: `KAN IK VAKER MEEDOEN MET DE HORECAVOUCHER WINACTIE?`,
    faq_answer_2: `Helaas! Je kan maar één keer meedoen met deze winactie. `,
    faq_question_3: `WANNEER WEET IK OF IK GEWONNEN HEB? EN WANNEER WORDEN DE LIPTON TERRASSEN VAN 2024 BEKEND GEMAAKT?`,
    faq_answer_3: `Goede vraag! Een antwoord op je vraag kan je vinden in het menu bij het tabblad ‘de winnaars’.`,
    faq_question_4: `WAAROM KAN IK MIJN FAVORIETE TERRAS NIET VINDEN?`,
    faq_answer_4: `Dat is balen! Waarschijnlijk neemt je favoriete terras niet deel aan deze activatie. Helaas is het niet mogelijk om je favoriete terras te nomineren. Het is aan de horeca ondernemer zelf om het terras op te geven voor deze activatie. Dat kan via onze service desk, check de `,
    faq_answer_4_href: `de actievoorwaarden `,
    faq_answer_4_2: `voor meer informatie.`,
    faq_question_5: `KAN IK EEN TERRAS NOMINEREN?`,
    faq_answer_5: `Helaas is het niet mogelijk om een terras te nomineren.`,
    faq_question_6: `KAN IK DE TUSSENSTANDEN VAN DE TERRASACTIVATIE ERGENS VINDEN?`,
    faq_answer_6: `Nee, dat is helaas niet mogelijk.`,
    faq_question_7: `TOT WANNEER KAN IK ALS HORECAONDERNEMER INSCHRIJVEN OM DEEL TE NEMEN AAN DEZE ACTIE?`,
    faq_answer_7: `Horeca ondernemers die vallen onder de actievoorwaarden van deze activatie kunnen 
      zich inschrijven tot 1 juli 2024`,

    //SCOREBOARD
    scoreboard_results_button: "SCOREBOARD",
    scoreboard_winners_button: "WINNAARS",
    // scoreboard tab
    scoreboard_title: "Top 25 van augustus",
    scoreboard_results_info: "Dit scoreboard wordt iedere maandag geupdatet.",
    scoreboard_results_footer: "Het scoreboard is geüpdate op \n26/08/2024.", // ------------- UPDATE WEEKLY -------------

    scoreboard_results_coming_soon:
      "Het scoreboard is live vanaf maandag 13 mei 2024",
    scoreboard_results_coming_soon_2:
      "De stemmen van augustus worden geteld. Op 9 september 2024 wordt bekendgemaakt wie de augustus winnaar is. Benieuwd naar de Top 3 en Social Media Winnaars? Check het 24 september op {link}",
    // winner tab
    scoreboard_winners_title: "WINNAAR",
    scoreboard_winners_may: "MEI 2024",
    scoreboard_winners_june: "JUNI 2024",
    scoreboard_winners_july: "JULI 2024",
    scoreboard_winners_august: "AUGUSTUS 2024",

    //END PAGE
    end_page_top_message: "inmiddels is deze actie",
    end_page_middle_message: "AFGELOPEN",
    end_page_bottom_message:
      "De stemmen van augustus zijn geteld. Op 9 september 2024 wordt bekendgemaakt wie de augustus winnaar is. Benieuwd naar de Top 3 en Social Media Winnaars? Check het 24 september op",
    lipton_website_button: "LIPTON ICE TEA",
    lipton_campaign: "LIPTON CAMPAGNES",
  },

  [LOCALES.ENGLISH]: {
    //HOLDING PAGE
    coming_soon: "COMING SOON",

    //DESKTOP ------------------------------------------------------------------------------------------------
    desktop_redirect_message:
      "THIS PROMOTION IS ONLY AVAILABLE FOR MOBILE DEVICES",
    desktop_redirect_scan: "SCAN THE QR-CODE & VOTE!",
    // footer
    desktop_lipton: "LIPTON",
    desktop_rules: "RULES",
    desktop_terms_conditions: "TERMS & CONDITIONS :",
    desktop_consumer: "VOOR CONSUMENTEN",
    desktop_terraces: "VOOR TERRASSEN",

    // LANDING PAGE ----------------------------------------------------------------------------------------------
    landing_page_heading: "WELCOME TO LIPTON! WHAT ARE YOU HERE FOR?",
    landing_terrace: "VOTE FOR YOUR FAVOURITE TERRACE",
    landing_club: "WIN PRIZES",

    // START PAGE ------------------------------------------------------------------------------------------------
    // intro
    start_page_welcome: "WELCOME",
    start_page_lipton: "LIPTON TERRACE",
    start_page_edition: "2024 EDITION",
    start_button: "START!",
    campaign_link: "THE LIPTON CLUB",
    // gif
    enter_button: "ENTER",
    // legal lines
    legal_line: "The  ",
    legal_line2: "\n apply to this campaign.",
    legal_href: "'Reglement van 'Hét Lipton Terras van 2024'",
    legal_line3: " and the ",
    legal_href2: " Terms and Conditions ",

    // VOTING PAGE ------------------------------------------------------------------------------------------------
    // initial search menu
    please_use_searchbar_no_venue_1: "FIND YOUR FAVOURITE",
    please_use_searchbar_no_venue_2: "LIPTON TERRACE",
    search_bar_placeholder: "Search here...",
    go_button: "GO",
    find_it_on_the_map: "FIND YOUR FAVOURITE TERRACE ON THE MAP",
    // input validation
    text_input_invalid: "Please fill in this field.",
    email_input_invalid: "Please fill in a valid e-mail address.",
    short_input: "Please try to search for a venue with more than 3 characters",
    wrong_input: "Add the name of your venue to the search query!",
    venue_not_found: "WE COULDN'T FIND YOUR FAVOURITE TERRACE 🧐",
    not_the_one: "Not the Lipton Terrace you are looking for?",
    please_use_searchbar: "Try using the search bar instead.",

    permission_denied:
      "It seems you have not given us access to your location. No problem! You can find your favourite terrace in the list view or grant your browser access to your location. \n \n Rather use the map? \n Allow your browser access to your location and refresh the page. ", // Check with team
    permission_denied_2:
      "It seems you have not given us access to your location.\n No problem! You can find your favourite terrace in the list view or grant your browser access to your location.",
    has_voted: "It seems you have already voted today! Come back tomorrow.",
    // map view
    your_favorite_terrace: "YOUR FAVOURITE LIPTON TERRACE IS...",
    vote_button: "VOTE",
    // list View
    pick_me: "CHOOSE",

    // THANKS FOR VOTING PAGE ------------------------------------------------------------------------------------------------
    // contest message popup
    contest_message_heading: "SIGN UP AND HAVE A CHANCE AT WINNING PRIZES",
    contest_message_text:
      "Sign up and have a chance at winning 1 of the 50 vouchers for your favourite terrace worth €100.-.",
    accept_contest_button: "YES, PLEASE",
    redirect_lipton_club: "LIPTON CLUB",
    view_terms_and_conditions: "To view the Terms and Conditions, ",
    view_terms_link: "click here",
    // competition popup
    sign_up_to_win_enter: "SIGN UP & HAVE A CHANCE TO WIN!",
    competition_sub_text:
      "Fill in the form below to have a chance at winning 1 of 50 vouchers worth €100.- to spend at your favourite terrace! ",
    raffle_input_placeholder: "I voted for this terrace, because...*",
    name_input_placeholder: "My first name is...*",
    enter_email_input_placeholder: "My email is...*",
    // age checkbox
    age_checkbox_1:
      "Yes, I confirm that I am at least 18 years old and I agree to the ",
    age_checkbox_tc_href_terms: "Terms & Conditions",
    age_checkbox_2: " and the ",
    age_checkbox_tc_href_rules: "Contest Rules",
    confirm_age_alert:
      "Je dient je leeftijd te bevestigen door het hokje aan te vinken.",
    // email checkbox
    unilever_marketing_opt_in_text_1: `Yes, I want to receive offers and updates from Lipton Ice Tea and other `,
    unilever_marketing_opt_in_text_anchor: `trusted Unilever Brands `,
    unilever_marketing_opt_in_text_2: `via email and online advertising, tailored to my interests and preferences.`,
    already_joined_email: "This email is already participating!",
    submit_button: "SUBMIT",
    // come back tomorrow
    come_back_tomorrow_1: "COME BACK TOMORROW TO",
    come_back_tomorrow_2: "VOTE",
    come_back_tomorrow_3: "AGAIN",
    see_campaign: "SEE OTHER",
    see_lipton_campaign: "LIPTON CAMPAGNES",

    // MENU ------------------------------------------------------------------------------------------------
    menu_heading: "ABOUT THIS CAMPAIGN",
    menu_scoreboard: "SCOREBOARD",
    menu_competition: "THE COMPETITION",
    menu_terms_conditions: "TERMS & CONDITIONS",
    menu_faq: "FREQUENTLY ASKED QUESTIONS / FAQs",
    menu_reglement_lipton: "CONTEST RULES LIPTON TERRACE 2024",
    menu_cookies: "COOKIES & SETTINGS",
    // DE WEDSTRIJD
    menu_prizes: "PRIZES!",
    menu_winners: "THE WINNERS",
    menu_rules: "THE COMPETITION RULES",
    // ACTIEVOORWAARDEN
    menu_terms_conditions_consumers: "FOR CONSUMERS",
    menu_terms_conditions_business: "FOR TERRACES",

    select_language: "CHOOSE YOUR LANGUAGE",

    // MENU CONTEN: PRIZES
    participants_vote_heading: "CONSUMER PRIZES",
    participants_vote_content_1: `You can join the "Favourite Lipton Terrace of 2024"-activation by casting your vote and afterwards filling in the sign-up form. Go to `,
    participants_vote_content_link: `www.hetliptonterras.nl `,
    participants_vote_content_2:
      "and vote for your fevourite terrace! After that, fill in the form and have a chance at winning 1 of the 50 vouchers usable at your favourite terrace worth €100.-. Pssst... did you know that you can vote for your favourite Lipton Terrace every day? ",
    participants_business_heading: "TERRACE OWNER PRIZES",
    participants_business_content: `Combined the prize money for the outlets amounts to €150.000.-. Amazing right? With this investment the winning outlets can improve even more on their beautiful terraces. How does this work though? Well, the contending Terraces need to get as many votes from their visitors in order to win. Once per month or at the end of the full campaign the terraces with the most votes are able to win the following prizes:`,
    participants_business_prizes_1: `• Most votes of all the terraces in the Netherlands at the end of the campaign? They win the grand prize of €50.000.- excl. VAT `,
    participants_business_prizes_2: `• Runner-up (2nd place) of all the terraces in the Netherlands at the end of the campaign? They win €30.000.- excl. VAT`,
    participants_business_prizes_3: `• Second Runner-up (3rd place) of all the terraces in the Netherlands at the end of the campaign? They win €15.000.- excl. VAT`,
    participants_business_prizes_4: `• Most votes of the month of May, June, July or August? That Lipton Terrace wins €10.000.- excl. VAT. This means that for each of these months a winner will be chosen, adding 4 more winners to the list!`,
    // MENU CONTEN: WINNERS
    winners_consumer_heading: "CONSUMERS",
    winners_consumer_content:
      "As soon as you have cast your vote for your favourite Lipton Terrace and filled out the form afterwards, you have a chance at winning 1 of the 50 vouchers (worth €100.-) to spend at your favourite Lipton Terrace. The winners will be made aware before the 22nd of September 2024. Mark your calendars!",
    winners_terrace_heading: "TERRACE OWNERS ",
    winners_terrace_content_1:
      "Who will get the most unique votes? The battle has begun! Your guests will be able to vote until the 31st of August 2024. There are two moments where we will announce winners, so put these dates in your calendars:",
    winners_terrace_content_2:
      "Monthly winners, meaning the terrace with the most unique votes of that specific month (May, June, July and August) will be made aware through the Scoreboard in the app environment, but will also be notified by our team soon after that month ends. The National Winner, meaning the terrace with the most unique votes at the end of the campaign (31st of August '23) , will be contacted in the beginning of September 2024.",
    // MENU CONTEN: RULES
    rules_vote_heading: "VOTING & COMPETITION",
    rules_content_1:
      "• As a consumer you are only allowed \n to vote once a day.",
    rules_content_2: `• Voting is only allowed for consumers (individuals). Lipton customers (horeca owners) are not allowed to vote.`,
    rules_content_3: `• To avoid competition fraud, Unilever will closely monitor the voting.  If Unilever has suspicion that votes are being cast fraudulently, action will be taken.  Unilever has the right to remove Lipton customers from The Lipton Terrace of the Year 2024 activation or remove the number of fraudulent votes.`,
    rules_win_heading: "ACTIVATION",
    rules_win_content: `• As a consumer you can only participate once (with your email address) in the horecavoucher competition.'`,
    rules_content_5:
      "• For more detailed regulations please check the Terms and Conditions in the menu.",
    // MENU CONTENT: FAQ
    faq_question_1: `HOW MANY TIMES CAN I VOTE?`,
    faq_answer_1: `You are able to vote once a day.`,
    faq_question_2: `HOW MANY TIMES CAN I PARTICIPATE IN THE CONSUMER HORECAVOUCHER COMPETITION?`,
    faq_answer_2: `You can participate in this competition once.`,
    faq_question_3: `WHEN ARE BOTH THE CONSUMERS AND CUSTOMER WINNERS ANNOUNCED?`,
    faq_answer_3: `Please look at the ‘winners’ tab in the menu screen`,
    faq_question_4: `WHY CAN’T I FIND MY FAVORITE TERRACE?`,
    faq_answer_4: `That’s a pity! Most probably your favorite Terrace is not participating in this activation. Unfortunately, you are not able to nominate your favorite terrace. The owner needs to get into contact via our service desk which can be found in the Terms and Conditions of this activation.`,
    faq_question_5: `CAN I NOMINATE A TERRACE THAT IS NOT SERVING LIPTON ICE TEA?`,
    faq_answer_5: `Unfortunately, you cannot. The competition for The Lipton Terrace 2024 is only for Lipton customers owning a Lipton terrace.`,
    faq_question_6: `WHERE CAN I FIND THE LEADING BOARD FOR THE LIPTON TERRACE 2024?`,
    faq_answer_6: `The leading board is not being published during the activation. The final province terrace winners are announced on the 1st of July 2024, and the national terrace winners are announced on the 1st of September 2024.`,
    faq_question_7: `WHAT IS THE FINAL DEADLINE TO SIGN UP AS A LIPTON CUSTOMER TO PARTICIPATE IN THE LIPTON TERRACE 2024 ACTIVATION?`,
    faq_answer_7: `Horeca owners that are allowed to participate accoring to the Terms and Conditions, can sign up up until the 1st of July 2024.`,

    //SCOREBOARD
    scoreboard_results_button: "SCOREBOARD",
    scoreboard_winners_button: "WINNERS",
    // scoreboard tab
    scoreboard_title: "Top 25 for August",
    scoreboard_results_info: "The scoreboard is updated every Monday.",
    scoreboard_results_footer:
      "This scoreboard has been updated on\n26/08/2024.", // ------------- UPDATE WEEKLY -------------

    scoreboard_results_coming_soon:
      "The scoreboard will be live from Monday the 13th of May 2024 onwards",
    scoreboard_results_coming_soon_2:
      "We are currently counting the votes for August. The winner will be announced on September 9th 2024. Are you curious about the Top 3 and Social Media winners? Visit {link} on the 24th of September.",
    // winner tab
    scoreboard_winners_title: "WINNER",
    scoreboard_winners_may: "MAY 2024",
    scoreboard_winners_june: "JUNE 2024",
    scoreboard_winners_july: "JULY 2024",
    scoreboard_winners_august: "AUGUST 2024",

    //END PAGE
    end_page_top_message: "inmiddels is deze actie",
    end_page_middle_message: "AFGELOPEN",
    end_page_bottom_message:
      "De stemmen van augustus zijn geteld. Op 9 september 2024 wordt bekendgemaakt wie de augustus winnaar is. Benieuwd naar de Top 3 en Social Media Winnaars? Check het 24 september op",
    lipton_website_button: "LIPTON ICE TEA",
    lipton_campaign: "LIPTON CAMPAGNES",
  },
};
